import React from "react";
import styles from "./RequestAccessForm.module.scss";
import HubspotRequestAccessForm from "./HubspotRequestAccessForm";

const RequestAccessForm = ({ getRecordingHubspotFormId }) => {
  return (
    <div className={styles.wrapper}>
      <h4 className={styles.title}>Get the Recording</h4>
      <HubspotRequestAccessForm
        getRecordingHubspotFormId={getRecordingHubspotFormId}
      />
    </div>
  );
};

export default RequestAccessForm;
