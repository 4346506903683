import React, { useState, useEffect, useCallback } from "react";
import styles from "./Counter.module.scss";

const SECOND = 1000;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const Counter = ({ startDate }) => {
  const getTime = useCallback(() => {
    let time = startDate.getTime() - Date.now();
    const started = time <= 0;
    if (started) time = Date.now() - startDate.getTime();

    const days = Math.floor(time / DAY);
    time = time % DAY;

    const hours = String(Math.floor(time / HOUR)).padStart(2, "0");
    time = time % HOUR;

    const minutes = String(Math.floor(time / MINUTE)).padStart(2, "0");
    time = time % MINUTE;

    const seconds = String(Math.floor(time / SECOND)).padStart(2, "0");

    return { started, days, hours, minutes, seconds };
  }, []);

  const [time, setTime] = useState(getTime);

  useEffect(() => {
    const intId = setInterval(() => setTime(getTime()), SECOND);
    return () => clearInterval(intId);
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={styles.title}>
        <p>Hurry!</p>
        {time.started ? (
          <p>The Webinar Has Started</p>
        ) : (
          <p>This Webinar Starts In</p>
        )}
        <div className={styles.image} />
      </div>
      <div className={styles.cards}>
        {time.days ? (
          <div className={styles.days}>
            <div className={styles.digit}>{time.days}</div>
            <p>DAYS</p>
          </div>
        ) : null}
        <div className={styles.hours}>
          <div className={styles.digit}>{time.hours}</div>
          <p>HOURS</p>
        </div>
        <div className={styles.minutes}>
          <div className={styles.digit}>{time.minutes}</div>
          <p>MINUTES</p>
        </div>
        <div className={styles.seconds}>
          <div className={styles.digit}>{time.seconds}</div>
          <p>SECONDS</p>
        </div>
      </div>
    </div>
  );
};

export default Counter;
