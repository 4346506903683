import React, { useState, useEffect } from "react";
import styles from "./Hero.module.scss";
import Header from "../header/index";
import Title from "./Title";
import Counter from "./Counter";
import RequestAccessForm from "./RequestAccessForm";

const Hero = ({
  startDate: startDateProp,
  endDate: endDateProp,
  duration,
  topic,
  description,
  getRecordingHubspotFormId,
  onRegistrationLinkClick,
}) => {
  const [status, setStatus] = useState("upcoming");

  const startDate = new Date(startDateProp);
  const endDate = new Date(endDateProp);
  const startsIn = startDate.getTime() - Date.now();
  const endsIn = endDate.getTime() - Date.now();

  useEffect(() => {
    let timeoutId;

    if (startsIn > 0) {
      timeoutId = setTimeout(() => setStatus("started"), startsIn);
    } else if (endsIn > 0) {
      setStatus("started");
      timeoutId = setTimeout(() => setStatus("finished"), endsIn);
    } else if (status !== "finished") {
      setStatus("finished");
    }

    return () => clearTimeout(timeoutId);
  }, [status]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.heroWrapper}>
        <Header cls />
        <div className={styles.content}>
          <Title
            {...{
              status,
              date: startDate,
              duration,
              topic,
              description,
              onRegistrationLinkClick,
              isRegistrationActive: !!(endsIn > 0),
            }}
          />
          {endsIn > 0 ? (
            <Counter startDate={startDate} />
          ) : (
            <RequestAccessForm
              getRecordingHubspotFormId={getRecordingHubspotFormId}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Hero;
